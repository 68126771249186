import { isNavigationFailure } from 'vue-router';
import NProgress from 'nprogress'; // progress bar
import { getToken } from '../utils/localToken';

const allowList = ['login', 'forget', 'error', '404', 'NotFound'];

let that = this;
export function createRouterGuards(router) {
    router.beforeEach((to, from, next) => {
        NProgress.start();
        const token = getToken();
        if (token) {
            if (to.name === 'login') {
                next({ path: '/home' });
                NProgress.done();
            } else {
                // const hasRoute = router.hasRoute(to.name);
                if (!allowList.includes(to.name)) {
                    // 在免登录名单，直接进入
                    next();
                }
            }
        } else {
            if (allowList.includes(to.name)) {
                // 在免登录名单，直接进入
                next();
            } else {
                next({ path: '/login' });
                NProgress.done();
            }
        }
    });
    router.afterEach((to, form) => {
        if (form.path === '/study/details') {
            location.reload();
        }
        // finish progress bar
        NProgress.done();
    });
}
