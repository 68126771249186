import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    infoAllList: '',
    inWidth: 0,
  },
  mutations: {
    getInfoAllList(state, value) {
      state.infoAllList = value;
    },
    getWidth(state, value) {
      state.inWidth = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
