/**
 * 自定义 token 操作
 */

/**
 * 获取本地Token
 */
export const getToken = () => {
    return sessionStorage.getItem('token');
};

/**
 * 设置存储本地Token
 */
export const setToken = (setToken) => {
    return sessionStorage.setItem('token', setToken);
};

/**
 * 移除本地Token
 */
export const removeToken = () => {
    return sessionStorage.removeItem('token');
};
