<template>
    <div class="record-item">
        <div class="record-item-title">{{ recordTitle }}</div>
        <div class="record-item-tag" v-if="recordNotes === 0">
            <span
                    v-for="(tag, index) in recordImg"
                    :key="index"
                    :class="tagIndex === index ? 'active' : ''"
            >
                {{ tag.memberName }} ({{ tag.scenes.length }})
            </span>
        </div>
        <div class="record-item-tag" v-if="recordNotes === 1">
            <span
                    v-for="(tag, index) in recordImg"
                    :key="index"
                    :class="tagIndex === index ? 'active' : ''"
            >
                {{ tag.memberName }} ({{ tag.notes.length }})
            </span>
        </div>
        <div v-if="recordImg.length > 0">
            <div class="record-item-img" v-if="recordNotes === 0">
                <div class="item-list" v-for="(record, index) in recordImg[tagIndex].scenes" :key="index" @click="qullScreen(record, recordImg[tagIndex].scenes)">
                    <img :width="150" :src="cdnImgUrl + record" />
                </div>
            </div>
            <div class="record-item-img" v-else-if="recordNotes === 1">
                <div class="item-list" v-for="(record, index) in recordImg[tagIndex].notes" :key="index" @click="qullScreen(record, recordImg[tagIndex].scenes)">
                    <img :width="150" :src="cdnImgUrl + record" />
                </div>
            </div>
            <div class="record-item-img" v-else>
                <div class="item-list" v-for="(record, index) in recordImg[tagIndex].paths" :key="index" @click="qullScreen(record, recordImg[tagIndex].scenes)">
                    <img :width="150" :src="cdnImgUrl + record" />
                </div>
            </div>
        </div>
        <div class="modal-img" v-if="visible" @click="previewCancel">
            <img :src="imgUrl" :key="index" alt=""/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detailsdisplay",
        props: ['recordImg', 'recordTitle', 'recordNotes'],
        data() {
            return {
                cdnImgUrl: 'https://cdn-img.q-media.org.cn/',
                tagIndex: 0,
                visible: false,
                imgUrl: '',
                imgUrlAll: null,
            }
        },
        created() {
            // this.activeList();
        },
        methods: {
            qullScreen(record, recordAll) {
                this.imgUrl = this.cdnImgUrl + record;
                this.visible = true;
                this.imgUrlAll = recordAll.map((item) => {
                    return this.cdnImgUrl + item
                });
                console.log(this.imgUrlAll);
            },
            previewCancel() {
                this.visible = false;
            },
            onChange() {}
        },
    }
</script>

<style scoped lang="less">
    .record-item {
        color: white;
        padding: 15px 20px;
        text-align: left;
        .record-item-title {
            margin-bottom: 20px;
        }
        .record-item-tag {
            span {
                background: rgba(234, 234, 234, 0.6);
                border-radius: 4px;
                padding: 10px;
                color: rgba(255, 255, 255, 0.8);
                margin-right: 12px;
                cursor: pointer;
            }
            .active {
                background-color: rgba(229, 29, 26, 1);
            }
        }
        .record-item-img {
            width: 100%;
            justify-content: space-around;
            margin-top: 15px;
            .item-list {
                float: left;
                margin: 5px;
            }
            img {
                height: 150px;
                cursor: pointer;
            }
            &::after {
                content: '';
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
            }
        }
    }
    #code {
        text-align: center;
    }
    .modal-img {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        min-height: 100%;
        background-color: rgba(0,0,0,.7);
        text-align: center;
        overflow: hidden;
        img {
            height: 80%;
            max-height: 80%;
            width: auto!important;
            margin-top: 1%;
        }
    }
    .ant-carousel {
        height: 100%;
        overflow: hidden;
        .slick-slide {
            height: 95%;
        }
    }
</style>
