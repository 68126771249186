import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.less';
import '@/layout/your-theme-file.less'
import Antd from 'ant-design-vue';
import detailsdisplay from './components/DetailsDisplay'
import { createRouterGuards } from './router/router-guards';

function cdnImgUrl() {
  const infoAllList = localStorage.getItem('infoAllList');
  if (infoAllList) {
    return JSON.parse(infoAllList).cdnImgUrl;
  }
}

Vue.prototype.$cdnImgUrl = cdnImgUrl;
Vue.component('details-display', detailsdisplay);
Vue.config.productionTip = false;
Vue.use(Antd);
createRouterGuards(router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
