import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import(/* webpackChunkName: "about" */ '@/layout/index'),
    children: [
      {
        path: '/study/study',
        name: '课程学习',
        component: () => import(/* webpackChunkName: "about" */ '@/views/study/study.vue'),
      },
      {
        path: '/study/details',
        name: '课程详情',
        component: () => import(/* webpackChunkName: "about" */ '@/views/study/details.vue'),
      },
      {
        path: '/study/record',
        name: '学习记录',
        component: () => import(/* webpackChunkName: "about" */ '@/views/study/record.vue'),
      },
      {
        path: '/activity/activity',
        name: '线下活动',
        component: () =>
            import(/* webpackChunkName: "about" */ '@/views/activity/activity.vue'),
      },
      {
        path: '/activity/details',
        name: '线下活动详情',
        component: () =>
            import(/* webpackChunkName: "about" */ '@/views/activity/details.vue'),
      },
      {
        path: '/branch/branch',
        name: '支部风采',
        component: () => import(/* webpackChunkName: "about" */ '@/views/branch/branch.vue'),
      },
      {
        path: '/branch/details',
        name: '支部风采详情',
        component: () =>
            import(/* webpackChunkName: "about" */ '@/views/branch/details.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/login/index.vue'),
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import(/* webpackChunkName: "about" */ '@/views/forget/index.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/home/index.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "about" */ '@/views/404.vue'),
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
